<ng-container *ngIf="isTimeOutError; else errorTmp">
    <div class="my-auto">
        <app-illustration iconName="clock"
                          style="display: block; margin: 0 auto; width: 120px; max-width: 80%;"
                          [circleColor]="['warning'|color, .4]">
        </app-illustration>
        <div class="h6 text-center text-muted" style="line-height: 1.33">
            Please try again.<br/>
            For best results, adjust the filters to narrow your search.
        </div>
    </div>
</ng-container>

<ng-template #errorTmp>
    <div class="my-auto">
        <app-illustration iconName="times"
                          style="display: block; margin: 0 auto; width: 120px; max-width: 80%;"
                          [circleColor]="['danger'|color, .33]">
        </app-illustration>
        <div class="h6 text-center text-muted" style="line-height: 1.33">{{ errorMsg }}</div>
    </div>
</ng-template>
