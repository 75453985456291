import {Directive, Input, OnChanges, TemplateRef, ViewContainerRef} from '@angular/core';
import {RequestHandler, RequestStatus} from '../../../@core/utils/request-handler';
import {createComponent} from '../../../utils/component.utils';
import {SpinnerComponent} from '../../../@theme/components/spinner/spinner.component';
import {NotAvailableComponent} from '../not-available/not-available.component';
import {UnsubscribeComponent} from '../../../@core/fc-component';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {ContentDefinitionKey} from '../../../definitions/definitions';
import {ErrorMessageComponent} from '../error-message/error-message.component';

@Directive({
    selector: '[appRequestHandler]',
})
export class RequestHandlerDirective extends UnsubscribeComponent implements OnChanges {
    @Input('appRequestHandlerContentType') contentType: ContentDefinitionKey;

    @Input('appRequestHandler') requestHandler: RequestHandler<any, any>;

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef) {
        super();
    }

    ngOnChanges() {
        this.cancelRequest$.next();
        this.requestHandler?.status$.pipe(
            takeUntil(this.cancelRequest$),
            distinctUntilChanged()
        ).subscribe((status: RequestStatus) => {
            this.viewContainer.clear();
            if (status === 'PENDING') {
                this._showSpinner();
            } else if (status === 'SUCCESS') {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else if (status === 'ERROR') {
                if (this.requestHandler.error.status === 404) {
                    const componentRef = createComponent(NotAvailableComponent, this.viewContainer);
                    componentRef.instance.contentType = this.contentType;
                    componentRef.changeDetectorRef.detectChanges();
                } else {
                    const componentRef = createComponent(ErrorMessageComponent, this.viewContainer);
                    componentRef.instance.error = this.requestHandler.error;
                    componentRef.instance.errorMsg = this.requestHandler.errorMessage;
                    componentRef.changeDetectorRef.detectChanges();
                }
            }
        });
        if (!this.requestHandler) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }

    private _showSpinner() {
        const componentRef = createComponent(SpinnerComponent, this.viewContainer);
        componentRef.changeDetectorRef.detectChanges();
    }
}
