import {Component, Input, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TIMEOUT_HTTP_STATUSES} from '../../../utils/error.utils';

@Component({
    selector: 'app-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements OnInit {
    @Input() errorMsg: string;
    @Input() error: HttpErrorResponse;
    isTimeOutError: boolean;

    ngOnInit() {
        this.isTimeOutError = TIMEOUT_HTTP_STATUSES.includes(this.error?.status);
    }
}
