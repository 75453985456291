import {Component, Input, OnInit} from '@angular/core';
import {contextColors} from '../../../@theme/theme-colors';
import {ContentDefinitionKey, ContentDefinitions} from '../../../definitions/definitions';

@Component({
    selector: 'app-not-available',
    templateUrl: './not-available.component.html',
    styleUrls: ['./not-available.component.scss'],
})
export class NotAvailableComponent implements OnInit {
    @Input() contentType: ContentDefinitionKey;
    @Input() errorMsg: string;

    iconName: string;
    title = 'Not Available';
    caption: string;
    circleColor = [contextColors.warning, .33];

    ngOnInit() {
        const definition = ContentDefinitions[this.contentType];
        this.iconName = definition?.icon || 'exclamation-square';
        this.caption = this.errorMsg || `The selected ${definition?.name.capitalized || 'entry'} was not found or you have insufficient access`;
    }
}
